import React, { useState, MouseEvent } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  CssBaseline,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import pheonixlogo from "../assets/blacklogo.png";
import { NavbarProps1 } from "../interfaces/PheonixNavbarProps";
import themestyle from "../theme";

const PheonixNavbar: React.FC<NavbarProps1> = ({
  sections,
  activeTab,
  setActiveTab,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const tabs = [
    { label: "Web templates", ref: sections.templateRef },
    { label: "Plan & Pricing", ref: sections.featureRef },
    { label: "About us", ref: sections.aboutRef, link: "https://pheonixsolutions.com/#aboutus" },
    { label: "Feedback", ref: sections.testimonialRef },
    { label: "Contact us", ref: sections.contactRef },
  ];

  const handleTabClick = (
    index: number,
    ref: React.RefObject<HTMLDivElement>,
    link?: string
  ) => {
    setActiveTab(index);
    if (link) {
      window.open(link, "_blank"); 
    } else {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    handleMenuClose();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#ffffff",
          justifyContent: "space-around",
          height: isMobile ? "" : "96px",
        }}
      >
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <div>
              <img
                src={pheonixlogo}
                alt="pheonixlogo"
                style={{
                  height: isMobile ? 40 : "83px",
                  width: isMobile ? "" : "128px",
                }}
              />
            </div>
            {!isMobile && (
              <Box
                sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
              >
                {tabs.map((tab, index) => (
                  <Button
                    key={index}
                    onClick={() => handleTabClick(index, tab.ref, tab.link)}
                    sx={{
                      backgroundColor: "transparent",
                      marginRight: "16px",
                      textTransform: "none",
                      fontFamily: "Lexend",
                      fontSize: "16px",
                      color: "#263238",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {tab.label}
                  </Button>
                ))}
              </Box>
            )}
          </div>
          {isMobile && (
            <>
              <IconButton
                edge="end"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ width: "285px", marginTop: "10px", padding: "30px" }}
              >
                {tabs.map((tab, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleTabClick(index, tab.ref, tab.link)}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="133px"
                      marginLeft="30px"
                    
                    >
                      <ListItemText
                        primary={tab.label}
                        primaryTypographyProps={{
                          fontWeight: 600,  
                        }}
                      />
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default PheonixNavbar;
