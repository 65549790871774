import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { PhoenixButtonProps } from "../interfaces/PheonixButtonInterface"

const PhoenixButton: React.FC<PhoenixButtonProps> = ({ label, onClick, variant = 'contained', color = 'primary', sx, ...props }) => {
  return (
    <MuiButton variant={variant} color={color} onClick={onClick} {...props} 
    sx = {{...sx}}>
      {label}
    </MuiButton>
  );
};

export default PhoenixButton;
