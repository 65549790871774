import React, { useEffect } from "react";
import Contact from "../components/Contact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PheonixProps } from "../interfaces/HomeProps";
import About from "../components/About";
import Product from "../components/Product";
import Features from "../components/Features";
import Faq from "../components/Faq";
import Template from "../components/Template";
import Feedback from "../components/Feedback";

const Home: React.FC<PheonixProps> = ({ sections }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <About/>
      <Template sections={sections}/>
      <Product sections={sections} />
      <Features sections={sections} />
      <Feedback sections={sections} />
      <Contact sections={sections} />
      <Faq sections={sections} />
    </div>
  );
};
export default Home;
