import React from "react";
import themestyle from "../theme";
import { PheonixProps } from "../interfaces/HomeProps";
import { Typography, Box, Grid,  useTheme, useMediaQuery} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { feedbackimg } from "../data/ImageData";
import { TEXT_MESSAGES } from "../const";

const Feedback: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
        overflowX: "hidden"
      }}
    >
      <Grid
        container
        ref={sections.testimonialRef}
        style={{
          textAlign: "left",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          marginBottom: themestyle.spacing.medium,
        }}
        spacing={0}
      >
        <Box sx={{ textAlign: "center", padding: "40px" }}>
          <Typography
            color="#101828"
            component="h2"
            style={{
              fontWeight: 700,
              fontFamily: "Lexend",
              fontSize: "36px",
            }}
          >
           {TEXT_MESSAGES.Feedback}
          </Typography>
          <Typography
            color="#ffffff"
            paragraph
            style={{
              fontWeight: 400,
              fontFamily: "Lexend",
              fontSize: "24px",
              lineHeight: "33.6px",
              padding:"50px",
              textAlign:"center"
            }}
          >
            {TEXT_MESSAGES.FBDESCRIPTION}
          </Typography>
        </Box>
      </Grid>
      <Box sx={{ paddingBottom: "40px" }}>
        <Slider {...settings}>
          {feedbackimg.map((img, index) => (
            <Box key={index} sx={{ paddingBottom: "50px" }}>
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                style={{
                  width:!isSmallScreen? "400px":"200px",
                  height:!isSmallScreen ? "275px": "150px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </div>
  );
};

export default Feedback;
