import React, { useState, useEffect } from 'react';
import { IconButton, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface ScrollToTopButtonProps {
    resetActiveTab: () => void;
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ resetActiveTab }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
                resetActiveTab();
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [resetActiveTab]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Zoom in={isVisible}>
            <IconButton
                onClick={scrollToTop}
                style={{
                    position: 'fixed',
                    bottom: '2vh',
                    right: '2vh',
                    zIndex: 1000,
                    backgroundImage:"linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                    color: '#FFFFFF'
                }}
            >
                <KeyboardArrowUpIcon />
            </IconButton>
        </Zoom>
    );
};
export default ScrollToTopButton;