import React, { useState } from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme, Divider } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "../assets/twitter.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactModal from "./ContactModal";
import "../style.css";
import data from "../data/FooterData.json";
import footerlogo from "../assets/footerlogo.png";

const PheonixFooter: React.FC = () => {
  const [sections, setSections] = useState(data.sections);
  const [addresses, setAddresses] = useState(data.addresses);
  const [follow, setfollow] = useState(data["Follow Us"]);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const linkStyle = {
    color: "#FFFFFF",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      fontFamily: "Lexend"
    },
  };

  const iconComponents: Record<string, any> = {
    FacebookIcon: FacebookIcon,
    LinkedInIcon: LinkedInIcon,
    InstagramIcon: InstagramIcon,
  };

  const getIconComponent = (iconName: string) => {
    if (iconName === "TwitterIcon") {
      return (
        <img
          src={TwitterIcon}
          alt="Twitter"
          style={{ width: 22, height: 18, marginRight: "5px" }}
        />
      );
    }
    const IconComponent = iconComponents[iconName];
    return IconComponent ? <IconComponent sx={{ marginRight: "5px" }} /> : null;
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000",
        color: "#ffff",
        padding: { xs: "20px 10px", sm: "40px 20px", md: "60px 40px" },
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          textAlign: "left",
          justifyContent: { xs: "left", md: "space-around" },
        }}
      >
        {sections.map((section, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            key={index}
            sx={{
              display: "block",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontFamily: "Lexend",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              {section.heading}
            </Typography>

           
            {section.heading !== "Follow Us" &&
              section.items.map((item, idx) => (
                <div key={idx} style={{ cursor: "pointer", color: "#FFFFFF",fontFamily:"Lexend" }}>
                  {item.text === "Contact" ? (
                    <span
                      onClick={handleOpen}
                      style={{ cursor: "pointer", color: "#FFFFFF" }}
                    >
                      <Box component="span" sx={linkStyle}>
                        {item.text}
                      </Box>
                    </span>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Box
                        component="span"
                        sx={linkStyle}
                        display="flex"
                        alignItems="center"
                      >
                        {item.text}
                      </Box>
                    </a>
                  )}
                </div>
              ))}
          </Grid>
        ))}
<Grid
    item
    xs={12}
    sm={4}
    md={2}
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: { xs: "center", md: "flex-start" },
    }}
  >
   
    <Box
      component="img"
      src={footerlogo} 
      alt="Logo"
      sx={{
        width: "153px",
        height: "121px",
        objectFit: "contain",
        textAlign: "center",
        margin: "0 auto",
      }}
    />
  </Grid>
        <ContactModal open={modalOpen} onClose={handleClose} />
      </Grid>

      <Grid
        container
        sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
      >
        <Grid item xs={12} sm={12} md={2}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "18px",
              fontFamily: "Lexend",
              fontWeight: "bold",
              marginBottom: "50px",
              marginTop: isSmallScreen ? "20px" : 0,
              marginLeft: { xs: "0", md: "20px", xl: "30px" },
            }}
          >
            {data.Address}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={10} spacing={4} flexDirection="row" style={{justifyContent:"space-between"}}>
        <Grid display="flex" flexDirection="row" flex="1" style={{justifyContent:"space-between"}}>
        {addresses.map((address, index) => (
          <Grid item xs={10} sm={4} key={index} style={{justifyContent:"space-between"}} >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#FFFFFF",
                fontFamily: "Lexend",
                marginBottom: "10px",
                marginLeft: { xs: "0", md: "20px", xl: "60px" },
              }}
            >
              {address.region}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Lexend",
                fontSize: "16px",
                marginLeft: { xs: "0", md: "20px", xl: "60px" },
              }}
            >
              {address.content}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Lexend",
                fontSize: "16px",
                marginLeft: { xs: "0", md: "20px", xl: "60px" },
              }}
            >
              {address.content2}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Lexend",
                fontSize: "16px",
                marginLeft: { xs: "0", md: "20px", xl: "60px" },
              }}
            >
              {address.city}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Lexend",
                marginBottom: "10px",
                fontSize: "16px",
                marginLeft: { xs: "0", md: "20px", xl: "60px" },
              }}
            >
              {address.phone}
            </Typography>
          </Grid>
        ))}
</Grid>
<Grid display="flex" flexDirection="column" style={{fontWeight: "bold"}}>  Follow Us 
<Box
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    marginTop:"20px"
                  }}
                >
                  {follow.map((item, idx) => (
                    <div
                      key={idx}
                      style={{
                        cursor: "pointer",
                        color: "#FFFFFF",

                      }}
                    >
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <Box
                          sx={linkStyle}
                          display="flex"
                          alignItems="center"
                        >
                          {"icon" in item && getIconComponent(item.icon)}
                        </Box>
                      </a>
                    </div>
                  ))}
                </Box>
                </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: "#F4F4F4", marginY: 2, marginTop:12 }} />
      <Grid item xs={12} sx={{textAlign:isSmallScreen? "left":"center", fontWeight:"normal"}}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: "Lexend",
            marginTop: 2,
            marginBottom: isSmallScreen ? 0 : -5,
            marginLeft: { xs: "0", md: "20px", xl: "30px" },
          }}
        >
          {data.CopyrightText}
        </Typography>
      </Grid>
    </Box>
  );
};

export default PheonixFooter;
