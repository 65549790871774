import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import themestyle from "../theme";
import amico2 from "../assets/OBJECTS.png";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
const Features: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const firstTableData = data.features.planFeatures.slice(0, 11);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const items = [
    {
      title: "Basic Website",
      amount: "399€ 599€",
      bulletPoints: [
        "5 static web pages",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
    {
      title: "Store Essential",
      amount: "599€ 799€",
      bulletPoints: [
        "5 static pages",
        "dynamic pages ( products admin payment )",
        "flexible payment gateway",
        "1 social media integration",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
    {
      title: "Absolute Legend",
      amount: "999€ 1100€",
      bulletPoints: [
        "5 static pages",
        "3 dynamic pages ( products admin payment )",
        "chat functions ",
        "3rd-party app integration",
        "flexible payment gateway",
        "3 social media integrations",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
  ];
  return (
    <>
      <Grid
        container
        ref={sections.featureRef}
        sx={{
          textAlign: "center",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          padding: isMobile ? "16px" : "32px",
          color: "white",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              fontSize: isMobile ? "32px" : "36px",
              fontWeight: 600,
              fontFamily: "Lexend",
              color: "#101828",
              marginBottom: isMobile ? "20px" : "40px",
            }}
          >
            {data.features.FEATURES}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              fontSize: isMobile ? "18px" : isTablet ? "18px" : "24px",
              fontWeight: 400,
              textAlign: "center",
              lineHeight: isMobile ? "34px" : "32px",
              fontFamily: "Lexend",
              color: "#171A1F",
            }}
          >
            {data.features.FEATURES_TEXT}
          </Typography>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{
            padding: isMobile ? "0px" : isTablet ? "24px" : "50px",
            justifyContent: "center",
          }}
        >
          <Grid spacing={5} container>
            {items.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  padding="24px"
                  gap="0px"
                  border="1px solid #EAECF0"
                  borderRadius="16px"
                  sx={{
                    width: "360px",
                    height: "407px",
                    backgroundColor: "#FFFFFF",
                    color: "#1D2939",
                    textAlign: "left",
                    boxShadow: "10px 10px 20px 0px #146CC526",
                    "&:hover": {
                      border: "2px solid transparent",
                      borderImage:
                        "linear-gradient(90deg, #44C597 0%, #0177BF 100%) 1",
                      opacity: 0.9,
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#1D2939",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        background: "#F7F6F6",
                        padding: "5px",
                        borderRadius: "8px",
                        border: "2px solid transparent",
                        borderImage:
                          "linear-gradient(90deg, #44C597 0%, #0177BF 100%) 1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {item.amount.split(" ")[0]}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#626262",
                          textDecoration: "line-through",
                        }}
                      >
                        {item.amount.split(" ")[1]}
                      </Typography>
                    </Box>
                  </Box>
                  <List
                    dense
                    disablePadding
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "16px",
                        lineHeight: 1.0,
                      },
                    }}
                  >
                    {item.bulletPoints.map((point, i) => (
                      <ListItem
                        key={i}
                        disableGutters
                        sx={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          alignItems: "flex-start",
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "20px" }}>
                          <CircleRoundedIcon
                            sx={{
                              color: "#1D2939",
                              fontSize: "5px",
                              minWidth: "10px",
                              marginTop: "8px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            point.includes("(")
                              ? point.split("(").join("\n(")
                              : point
                          }
                          sx={{
                            fontSize: "16px",
                            whiteSpace: "pre-wrap",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Features;
