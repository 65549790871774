import React, { useState } from "react";
import themestyle from "../theme";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Link,
  Button,
  Box,
} from "@mui/material";
import ContactModal from "./ContactModal";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
import ContactImage from "../assets/contact-us.gif";
import EmailIcon from "../assets/mailicon.png";
import PublicIcon from "../assets/webicon.png";
import PhoneIcon from "../assets/phoneicon.png";

const Contact: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const [modalOpen, setModalOpen] = useState(false);

  const handleActionClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Grid
      container
      ref={sections.contactRef}
      style={{
        textAlign: "left",
        justifyContent: "center",
        marginTop: themestyle.spacing.medium,
        marginBottom: themestyle.spacing.medium,
        padding: isSmallScreen ? "20px" : "40px",
        gap: "50px",
      }}
      spacing={2}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        lg={8}
        spacing={0}
        style={{
          borderRadius: "8px",
          border: "1px solid transparent",
          borderImage: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
          borderImageSlice: 1,
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          style={{
            width: isSmallScreen ? "100%" : "781px",
            height: isSmallScreen ? "auto" : "342px",
            padding: "20px",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontSize: isSmallScreen
                ? themestyle.fontSizes.medium
                : themestyle.fontSizes.large,
              fontWeight: 600,
              fontFamily: "Lexend",
              color: "#101828",
              marginBottom: themestyle.spacing.small,
            }}
          >
            {data.contact.Contact}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: "Lexend",
              marginBottom: isSmallScreen ? "20px" : "40px",
              fontSize: "18px",
            }}
          >
            {data.contact.Build}
          </Typography>

          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: isSmallScreen ? "14px" : "18px",
              fontWeight: 600,
              fontFamily: "Lexend",
              marginBottom: themestyle.spacing.small,
              marginTop: "20px",
            }}
          >
            <Box
              component="img"
              src={PublicIcon}
              alt="Website Icon"
              style={{ marginRight: "8px", width: "16px", height: "16px" }}
            />
            <Link
              href={data.contact.WebsiteLink}
              target="_blank"
              rel="noopener"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {data.contact.Website}
            </Link>
          </Typography>

          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: isSmallScreen ? "14px" : "18px",
              fontWeight: 600,
              fontFamily: "Lexend",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              src={EmailIcon}
              alt="Email Icon"
              style={{ marginRight: "8px", width: "16px", height: "12px" }}
            />
            <a
          href="mailto:support@pheonixsolutions.com"
          style={{
            color: "inherit",
            textDecoration: "underline",
          }}
        >
          {data.contact.Mail}
        </a>
          </Typography>
        </Grid>

        {isSmallScreen && (
          <>
            <Grid
              container
              alignItems="left"
              style={{
                fontSize: "14px",
                fontFamily: "Lexend",
                marginTop: "10px",
                padding: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                <Box
                  component="img"
                  src={PhoneIcon}
                  alt="Phone Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  {data.contact.India}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IndiaContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.Ireland}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IrelandContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginTop: "10px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.US}
                </Box>
                <Box style={{ fontWeight: 400 }}>{data.contact.USContact}</Box>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          md={2}
          style={{
            display: "flex",
            justifyContent: isSmallScreen ? "center" : "flex-start",
            marginTop: "20px",
          }}
        >
          <Box
            component="img"
            src={ContactImage}
            alt="Contact Illustration"
            style={{
              width: isSmallScreen ? "80%" : isLaptopSmall ? "158px" : "358px",
              height: isSmallScreen ? "auto" : "201px",
              maxWidth: "358px",
            }}
          />
        </Grid>
        {!isSmallScreen && (
          <Grid
            container
            alignItems="center"
            style={{
              fontSize: "18px",
              fontFamily: "Lexend",
              marginTop: isLaptopSmall ? "-100px" : "-190px",
            }}
          >
            <Grid
              item
              xs={12}
              md="auto"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              <Box
                component="img"
                src={PhoneIcon}
                alt="Phone Icon"
                style={{ marginRight: "8px", width: "16px", height: "16px" }}
              />
              <Box
                component="span"
                style={{ fontWeight: 700, marginRight: "5px" }}
              >
                {data.contact.India}
              </Box>
              <Box style={{ fontWeight: 400 }}>
                {data.contact.IndiaContact}&nbsp;
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <Box
                component="span"
                style={{ fontWeight: 700, marginRight: "5px" }}
              >
                | {data.contact.Ireland}
              </Box>
              <Box style={{ fontWeight: 400 }}>
                {data.contact.IrelandContact}&nbsp;
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <Box
                component="span"
                style={{ fontWeight: 700, marginRight: "5px" }}
              >
                | {data.contact.US}
              </Box>
              <Box style={{ fontWeight: 400 }}>{data.contact.USContact}</Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        lg={3}
        xs={12}
        md={4}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "20px",
          borderRadius: "8px",
          marginTop: isSmallScreen ? "20px" : "0",
          backgroundColor: "#f7f7f7",
          textAlign: "left",
          color: "#101828",
          width: isSmallScreen ? "100%" : "478px",
          height: isSmallScreen ? "auto" : "342px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: "Lexend",
            fontWeight: 500,
            color: "#101828",
            fontSize: isSmallScreen ? "24px" : "32px",
          }}
        >
          {data.contact.Showcase}
          <br />
          {data.contact.WhatyouDo}
          <br />
          <span style={{ color: "#101828", fontWeight: 700 }}>
            {data.contact.days}
          </span>
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontFamily: "Lexend",
            marginBottom: themestyle.spacing.medium,
            fontSize: isSmallScreen ? "18px" : "24px",
          }}
        >
          {data.contact.Build}
        </Typography>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            variant="contained"
            onClick={handleActionClick}
            style={{
              backgroundImage:
                "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
              color: "#fff",
              textTransform: "none",
              fontFamily: "Lexend",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 24px",
            }}
          >
            {data.contact.Request}
          </Button>
        </Box>
        <ContactModal open={modalOpen} onClose={handleCloseModal} />
      </Grid>
    </Grid>
  );
};

export default Contact;
