import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  IconButton,
  useTheme,
} from "@mui/material";
import FormfacadeEmbed from "@formfacade/embed-react";
import data from "../data/data.json";
import CloseIcon from "@mui/icons-material/Close";
import {ContactModalProps} from "../interfaces/ContactModalProps";

const ContactModal: React.FC<ContactModalProps> = ({ open, onClose }) => {
  const [formVisible, setFormVisible] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const hideFormFacadeLogo = () => {
      const logoElement = document.querySelector(
        "a.ff-powered-img"
      ) as HTMLElement;
      if (logoElement) {
        logoElement.style.display = "none";
      }
    };
    const hideWatermark = () => {
      const watermarkElement = document.querySelector(
        ".ff-watermark"
      ) as HTMLElement;
      if (watermarkElement) {
        watermarkElement.style.setProperty("display", "none", "important");
      } else {
      }
    };
    const logoObserver = new MutationObserver(hideFormFacadeLogo);
    const watermarkObserver = new MutationObserver(hideWatermark);
    logoObserver.observe(document.body, { childList: true, subtree: true });
    watermarkObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      logoObserver.disconnect();
      watermarkObserver.disconnect();
    };
  }, []);

  const handleSubmit = () => {
    setFormVisible(true);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contact-modal-title"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: isSmallScreen ? "90%" : "50vw",
          height: isSmallScreen ? "80vh" : "80vh",
          maxWidth: "none",
          overflow: "visible",
        },
      }}
    >
      {" "}
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <FormfacadeEmbed
          formFacadeURL={data.contact.ContactForm}
          onSubmitForm={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContactModal;
