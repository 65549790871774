export const TEXT_MESSAGES = {
  FEATURES: "Plan",
  BAIC: "Features",
  PRO: "Cost",
  Premium: "Premium",
  Custom: "Custom",
  Monthly: "Monthly",
  yearly: "Yearly",
  TEM_TXT: "Template sections",
  PREVIEW: "Preview",
  Feedback:  "Customer Feedback",
  FBDESCRIPTION: `We’re dedicated to your success! Our team is here to support you
            every step of the way, providing solutions, insights, and tools to
            help you achieve your goals. From onboarding to ongoing support,
            we’re committed to ensuring you make the most out of our product!`
};
export const FOOTER_MESSAGES = {
  PRODUCT: "Product",
  RESOURCES: "Resources",
  COMPANY: "Company",
  LANGUAGE: "Language",
  FEATURES: "Features",
  PRICING: "Pricing",
  BLOG: "Blog",
  USER_GUIDES: "User guides",
  WEB: "Webinars",
  ABOUT: "About",
  JOINUS: "Join us",
  FOOTER_TXT: "PheonixSolutions Team",
  PRIVACY: " . Privacy",
  TERMS: " . Terms",
  SITE_MAP: " . Sitemap",
  Linkdin: "https://www.linkedin.com/company/pheonixsolutions",
  Youtube: "https://youtube.com",
  FB: "https://www.facebook.com/pheonixsolutionss",
  Pheonix_link: "https://pheonixsolutions.com/",
};
