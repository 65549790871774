import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";
import approveicon from "../assets/Check icon.png";
import closeicon from "../assets/closeicon.png";
import fb1 from "../assets/fb1.png";
import fb2 from "../assets/fb2.png";
import fb3 from "../assets/fb3.png";
import fb4 from "../assets/fb4.png";
import fb5 from "../assets/fb5.png";
import fb6 from "../assets/fb6.png";

export const feedbackimg = [ fb1,fb2,fb3,fb4,fb5,fb6];
export const imageUrls = [img1, img2, img3, img4, img5, img6];
export const approveIcon = (
  <img
    src={approveicon}
    alt="Approved"
    style={{ width: "20px", height: "20px" }}
  />
);
export const rejectIcon = (
  <img
    src={closeicon}
    alt="Rejected"
    style={{ width: "20px", height: "20px" }}
  />
);
