import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import data from "../data/data.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoenixButton from "../components/PhoenixButton";
import themestyle from "../theme";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PheonixProps } from "../interfaces/HomeProps";
import ContactModal from "./ContactModal";

const Faq: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const faqData = data.faq.faqdata;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [modalOpen, setModalOpen] = useState(false);

  const handleActionClick = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          padding: isSmallScreen ? "48px 32px" : "20px 96px 64px",
          background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontFamily: "Lexend",
            fontWeight: 700,
            fontSize: "36px",
            color: "#101828",
            marginBottom: themestyle.spacing.small,
            textAlign: "center",
            marginTop: themestyle.spacing.small,
          }}
        >
          {data.faq.FAQ}
        </Typography>
        <Typography
          style={{
            color: "#ffffff",
            marginTop: themestyle.spacing.small,
            marginBottom: themestyle.spacing.large,
            fontSize: "24px",
            fontWeight: 400,
            fontFamily: "Lexend",
          }}
        >
          {data.faq.FAQDESC}
        </Typography>

        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            style={{
              marginBottom: "24px",
              width: "100%",
              borderRadius: "8px",
              maxWidth: "1440px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography style={{ fontWeight: 600, fontFamily: "Lexend" }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  textAlign: "left",
                  fontFamily: "Lexend",
                  fontSize: "14px",
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </>
  );
};
export default Faq;
